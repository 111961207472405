import { amgApi } from '@/service/axios'

class TypeRequestService {

  //REGISTER NEW TYPE REQUEST
  async createTypeRequest(params) {
    try {
      const data = await amgApi.post('/commons/employee-claims/type-requests/create-claim-types', params)
      return data
    } catch (error) {
      throw error
    }
  }

  // GET TYPE REQUEST BY MODULE
  async getTypeRequestByModule(params) {
    try {
      const data = await amgApi.post('/commons/employee-claims/type-requests/get-claim-types-paginated', params)
      return data
    } catch (error) {
      throw error
    }
  }

  // CHANGE STATUS TO TYPE REQUEST
  async changeClaimTypesStatus(params) {
    try {
      const data = await amgApi.post('/commons/employee-claims/type-requests/change-claim-types-status', params)
      return data
    } catch (error) {
      throw error
    }
  }

  // GET TYPE REQUEST BY ID
  async getTypeRequestById(params) {
    try {
      const data = await amgApi.post('/commons/employee-claims/type-requests/get-type-request-by-id', params)
      return data
    } catch (error) {
      throw error
    }
  }

  // UPDATE TYPE REQUEST BY ID
  async updateTypeRequest(params) {
    try {
      const data = await amgApi.post('/commons/employee-claims/type-requests/update-claim-types', params)
      return data
    } catch (error) {
      throw error
    }
  }

  // DELETE TYPE REQUEST BY ID
  async deleteClaimTypeRequest(params) {
    try {
      const data = await amgApi.post('/commons/employee-claims/type-requests/delete-claim-types', params)
      return data
    } catch (error) {
      throw error
    }
  }
}
export default new TypeRequestService()
