// ADVANCED FILTERS TO TABLE OFFICE HOURS
export default [
    {
      // [0]
      type: "datepicker",
      margin: true,
      showLabel: true,
      label: "From",
      placeholder: "Date",
      class: "font-small-3",
      model: null,
      locale: "en",
      dateFormatOptions: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      cols: 6,
      visible: true
    },
    {
      // [1]
      type: "datepicker",
      margin: true,
      showLabel: true,
      label: "To",
      placeholder: "Date",
      class: "font-small-3",
      model: null,
      locale: "en",
      dateFormatOptions: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      cols: 6,
      visible: true
    },
    {
      type: "select",
      margin: true,
      showLabel: true,
      label: "Status",
      model: null,
      options: [
        {id: 1, label: 'ACTIVE'},
        {id: 0, label: 'INACTIVE'}
      ],
      reduce: "id",
      selectText: "label",
      cols: 6,
      visible: true
    },
    {
      type: "select",
      margin: true,
      showLabel: true,
      label: "Module",
      model: null,
      options: [
        {id: 17, label: 'HUMAN TALENT'},
        {id: 19, label: 'LOGISTIC'}
      ],
      reduce: "id",
      selectText: "label",
      cols: 6,
      visible: false
    },
  ];