<template>
  <div>
    <!-- BUTTON SEND TO #actions SLOT IN HEADER SLOT WITH PORTALS  -->
    <div to="settings-actions">
      <div  class="w-100 d-flex justify-content-end">
        <b-button variant="success" class="btn-black mt-1 mr-1" @click="openModalTypeRequest(0)">
        <feather-icon icon="PlusIcon" />
        <span> NEW </span>
      </b-button>
      </div>
    </div>
    <modal-create-type-request
      v-if="modalTypeRequest"
      :isUpdate="isUpdate"
      :idEdit="idEdit"
      @hidden="closeModalTypeRequest"
      @saveTypeRequest="saveTypeRequest"
    />

    <filter-slot
      :filter="visibleFilters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="refreshTable"
    >
      <b-table
        ref="typeRequestTable"
        slot="table"
        sticky-header="70vh"
        no-provider-filtering
        :busy.sync="isBusy"
        :items="myProvider"
        :fields="visibleFields"
        :per-page="paginate.perPage"
        :current-page="paginate.currentPage"
        :filter="filter"
        show-empty
        responsive
        hover
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(module_id)="data">
          {{ getModuleNameString(data.item.module_id) }}
        </template>
        <template #cell(description)="data">
          <change-sms :SmsData="data.item.description" />
        </template>
        <template #cell(created_at)="data">
          {{ data.item.created_at | myGlobalDay }}
        </template>
        <template #cell(status)="data">
          <b-badge
            class="cursor-pointer"
            pill
            :variant="data.item.status == 1 ? 'light-success' : 'light-danger'"
          >
            {{ data.item.status == 1 ? "ACTIVE" : "INACTIVE" }}
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <feather-icon
            icon="Edit2Icon"
            size="15"
            class="text-warning cursor-pointer mr-1"
            v-b-tooltip.hover.bottom="'Edit'"
            @click="openModalTypeRequest(1, data.item.id)"
          />
          <feather-icon
            class="cursor-pointer mr-1"
            :icon="data.item.status == 1 ? 'ThumbsDownIcon' : 'ThumbsUpIcon'"
            :class="data.item.status == 1 ? 'text-danger' : 'text-success'"
            v-b-tooltip.hover.bottom="
              data.item.status == 1 ? 'Deactivate' : 'Activate'
            "
            @click="changeStatus(data.item.id, data.item.status)"
          />
          <feather-icon
            icon="TrashIcon"
            size="15"
            class="text-danger cursor-pointer"
            v-b-tooltip.hover.bottom="'Delete'"
            @click="deleteTypeRequest(data.item.id)"
          />
        </template>
      </b-table>
    </filter-slot>
  </div>
</template>
<script>
// components
import ChangeSms from "@/views/commons/components/clients/components/clientsSms/ChangeSms.vue";
//modals
import ModalCreateTypeRequest from "@/commons/employee-claims/components/settings/components/modals/ModalCreateTypeRequest.vue";
// services
import TypeRequestService from "@/commons/employee-claims/components/settings/services/type-request.service.js";
//data
import Fields from "@/commons/employee-claims/components/settings/components/data/settings-type-request.fields.data.js";
import Filters from "@/commons/employee-claims/components/settings/components/data/settings-type-request.filters.data.js";
import { mapGetters } from "vuex";
export default {
  components: {
    ModalCreateTypeRequest,
    ChangeSms,
  },
  data() {
    return {
      //modal actions vars
      modalTypeRequest: false,
      // pagination vars
      filter: Filters,
      fields: Fields,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by description...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      isBusy: false,
      startPage: null,
      toPage: null,
      isLoading: true,
      totalRows: 0,
      deleteOrNot: 1,
      isUpdate: 0,
      idEdit: null,
    };
  },
  // getTypeRequestByModule
  methods: {
    openModalTypeRequest(action, id = null) {
      this.isUpdate = !!action;
      this.idEdit = id;
      this.modalTypeRequest = true;
    },
    closeModalTypeRequest() {
      this.modalTypeRequest = false;
    },
    saveTypeRequest() {
      this.closeModalTypeRequest();
      this.refreshTable();
    },
    async myProvider(ctx) {
      try {
        let params = {
          order_by: "created_at",
          orden: "desc",
          perpage: this.paginate.perPage,
          npage: this.paginate.currentPage,
          module_id: this.filter[3].model
            ? this.filter[3].model
            : this.moduleId,
          date_range_to: this.filter[0].model,
          date_range_from: this.filter[1].model,
          deleted_or_not: this.deleteOrNot,
          status: this.filter[2].model,
          campo: this.filterPrincipal.model,
        };
        const { data } = await TypeRequestService.getTypeRequestByModule(
          params
        );
        // assign totalRows from data response
        this.totalRows = data.total;
        // assign startPage from data or default
        this.startPage = data.from ? data.from : 0;
        // assign toPage from data of default
        this.toPage = data.to ? data.to : 0;
        return data.data;
      } catch (error) {
        throw error;
      }
    },
    async changeStatus(id, status) {
      try {
        this.addPreloader();
        let params = {
          id: id,
          status: status ? 0 : 1,
        };
        const { data } = await TypeRequestService.changeClaimTypesStatus(
          params
        );
        this.showSuccessSwal(null, data.message);
        this.refreshTable();
      } catch (error) {
        throw error;
      } finally {
        this.removePreloader();
      }
    },
    async deleteTypeRequest(id) {
      const result = await this.showConfirmSwal();
      if (result.isConfirmed) {
        try {
          this.addPreloader();
          let params = {
            id: id,
          };
          const { data } = await TypeRequestService.deleteClaimTypeRequest(
            params
          );
          this.showSuccessSwal(null, "Type Request deleted successfully!");
          this.refreshTable();
        } catch (error) {
          this.showErrorSwal(error);
        } finally {
          this.removePreloader();
        }
      }
    },
    refreshTable() {
      this.$refs.typeRequestTable.refresh();
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    visibleFields() {
      if (this.$route.matched[0].meta.module == 16) {
        this.fields.find((item) => item.key == "module_id").visible = true;
      }
      return this.fields.filter((field) => field.visible);
    },
    visibleFilters() {
      if (this.$route.matched[0].meta.module == 16) {
        this.filter.find((item) => item.label == "Module").visible = true;
      }else{
        this.filter.find((item) => item.label == "Status").cols = 12
      }
      return this.filter.filter((filter) => filter.visible);
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  created() {},
};
</script>

<style>
.description-column {
  max-width: 200px;
}
</style>