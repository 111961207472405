// ADVANCED FILTERS TO TABLEBANKACCOUNTS
export default [
  {
    key: "module_id",
    label: "module",
    visible: false,
  },
  {
    key: "description",
    visible: true,
    tdClass: "description-column"
  },
  {
    key: "created_by",
    visible: true
  },
  {
    key: "created_at",
    visible: true
  },
  {
    key: "status",
    visible: true
  },
  {
    key: "actions",
    visible: true
  },
];