<template>
  <b-modal ref="create-type-request-modal" @hidden="$emit('hidden')" centered>
    <!-- TITLE MODAL -->
    <template #modal-title>
      <span class="font-weight-bolder" v-if="moduleId == 16">
        {{ actionModal + " TYPE REQUEST " }}
      </span>
      <span class="font-weight-bolder" v-else>
        {{ actionModal + " TYPE REQUEST FOR: " + moduleName }}
      </span>
    </template>
    <!-- BODY MODAL -->
    <template #default>
      <validation-observer ref="form">
        <div class="pt-2 mb-2">
          <b-row class="mb-2">
            <b-col sm="12" md="4" lg="3" xl="3">
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-group label="Departament">
                  <v-select
                    v-if="moduleId == 16"
                    v-model="typeRequest.departament"
                    :options="[
                      { id: 17, label: 'HUMAN TALENT' },
                      { id: 19, label: 'LOGISTIC' },
                    ]"
                    :reduce="(option) => option.id"
                  />
                  <b-form-input
                    v-else
                    v-model="typeRequest.departament"
                    :disabled="true"
                    :class="errors[0] ? 'is-invalid' : 'is-valid'"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="12" md="8" lg="9" xl="9">
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-group label="Description">
                  <b-form-input
                    v-model="typeRequest.description"
                    @keyup.enter="
                      isUpdate ? updateTypeRequest() : createTypeRequest()
                    "
                    :class="errors[0] ? 'is-invalid' : ''"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </div>
      </validation-observer>
    </template>
    <template #modal-footer>
      <b-row class="d-flex justify-content-end">
        <b-button
          v-if="!isUpdate"
          variant="outline-success"
          @click="createTypeRequest()"
        >
          SAVE
          <feather-icon icon="SaveIcon" />
        </b-button>
        <b-button v-else variant="outline-success" @click="updateTypeRequest()">
          UPDATE
          <feather-icon icon="SaveIcon" />
        </b-button>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import FloatLabelInput from "@/utils/plugins/FloatLabelInput.vue"
import TypeRequestService from "@/commons/employee-claims/components/settings/services/type-request.service.js"
import { mapGetters } from "vuex"
// import vSelect from "vue-select";
export default {
  data() {
    return {
      typeRequest: {
        departament: "",
        description: "",
      },
      moduleInput: "",
      officeHour: {
        module_id: "",
        module: "",
        description: "",
        days: "",
        time_from: "",
        time_to: "",
      },
      daysOfWeek: [
        { id: 1, name: "Monday" },
        { id: 2, name: "Tuesday" },
        { id: 3, name: "Wednesday" },
        { id: 4, name: "Thursday" },
        { id: 5, name: "Friday" },
        { id: 6, name: "Saturday" },
        { id: 7, name: "Sunday" },
      ],
    }
  },
  props: {
    isUpdate: {
      type: Boolean,
      default: false,
    },
    idEdit: {
      type: Number,
      required: false,
    },
  },
  components: {
    FloatLabelInput,
  },
  methods: {
    async getTypeRequestEdit() {
      try {
        this.addPreloader()
        let params = {
          id: this.idEdit,
        }
        const { data } = await TypeRequestService.getTypeRequestById(params)
        if (data[0]) {
          this.typeRequest.description = data[0].description
        }
      } catch (error) {
        this.showErrorSwal(error)
      } finally {
        this.removePreloader()
      }
    },
    async createTypeRequest() {
      const validation = await this.$refs.form.validate()
      if (validation) {
        try {
          this.addPreloader()
          let params = {
            module_id:
              this.moduleId == 16
                ? this.typeRequest.departament
                : this.moduleId,
            description: this.typeRequest.description,
            user_id: this.currentUser.user_id,
          }
          const { data } = await TypeRequestService.createTypeRequest(params)
          if (data.code == 2) {
            this.showInfoSwal(null, data.message)
          } else {
            this.showSuccessSwal(null, data.message)
          }
          this.$emit("saveTypeRequest")
        } catch (error) {
          this.showErrorSwal(error)
        } finally {
          this.removePreloader()
        }
        // }
      }
    },
    async updateTypeRequest() {
      this.addPreloader()
      try {
        let params = {
          id: this.idEdit,
          description: this.typeRequest.description,
        }
        const { data } = await TypeRequestService.updateTypeRequest(params)
        this.showSuccessSwal(null, data.message)
        this.$emit("saveTypeRequest")
      } catch (error) {
        this.showErrorSwal(error)
        throw error
      } finally {
        this.removePreloader()
      }
    },
  },
  computed: {
    actionModal() {
      return this.isUpdate ? "UPDATE" : "NEW"
    },
    moduleId() {
      return this.$route.matched[0].meta.module
    },
    moduleName() {
      return this.getModuleNameString(this.$route.matched[0].meta.module)
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    this.toggleModal("create-type-request-modal")
    this.officeHour.module = this.moduleName
    this.typeRequest.departament = this.moduleName
    if (this.idEdit) {
      this.getTypeRequestEdit()
    }
  },
}
</script>
